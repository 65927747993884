import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { themeGet, Box, Text } from '@primer/components'
import styled from 'styled-components'

import PageSetup from '../components/layout/PageSetup'
import LinkLight from '../components/reusable/LinkLight'

const StyledUsername = styled(Text)`
  border-radius: ${themeGet('radii.2')};

  &:hover {
    background-color: ${themeGet('colors.accent.0')};
    color: ${themeGet('colors.primary')};
  }
`

const CleanList = styled.ul`
  list-style: none;
`

const ContactPage = () => (
  <StaticQuery query={graphql`
      query ContactsQuery {
        allLinksYaml {
          nodes {
            name
            url
            linkType
            contactOrder
          }
        }
      }`
  } render={data => {

    let contactLinks = data.allLinksYaml.nodes
      .filter(node => node.linkType === 'social')
      .sort((a, b) => a.contactOrder - b.contactOrder)

    return (
      <PageSetup pageTitle="Contact">
        <Text as="p" fontSize={4} fontWeight="light" mt={6}>
          Find me online with{' '}
          <StyledUsername
            as="span"
            fontFamily="mono"
            bg="primary.5"
            borderRadius={2}
            py={1}
            px={2}
          >
            /acknosyn
          </StyledUsername>
        </Text>

        <Box mb={12}>
          <CleanList>
            {contactLinks.map((link, i) =>
              <Box as="li" py={2} key={'linkitem' + i}>
                <LinkLight href={link.url}
                           fontSize={4}
                           py={1}
                           px={2}
                           black
                           key={'link' + i}>{link.name}</LinkLight>
              </Box>)}
          </CleanList>
        </Box>
      </PageSetup>
    )
  }}/>
)

export default ContactPage
