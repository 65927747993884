import React from 'react'
import { Link, Text } from '@primer/components'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'

import theme from '../../config/theme'

const AnchorLink = styled(Link)`
  text-decoration: underline;
  color: ${props =>
    props.black ? theme.colors.bodytext : theme.colors.primary};

  :hover {
    color: ${props =>
      props.black ? theme.colors.primary : theme.colors.secondary};
    text-decoration: underline;
  }
`

const RouterLink = styled(GatsbyLink)`
  text-decoration: underline;
  color: ${props =>
    props.black ? theme.colors.bodytext : theme.colors.primary};

  :hover {
    color: ${props =>
      props.black ? theme.colors.primary : theme.colors.secondary};
    text-decoration: underline;
  }
`

const LinkLight = ({ href, to, display, black, ...rest }) => {
  return href ? (
    <AnchorLink
      href={href}
      fontWeight={display ? '700' : ''}
      black={black ? 1 : 0}
      {...rest}
    />
  ) : (
    <Text
      as={RouterLink}
      to={to}
      fontWeight={display ? '700' : ''}
      black={black ? 1 : 0}
      {...rest}
    />
  )
}

export default LinkLight
